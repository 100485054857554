import { useMemo } from 'react';
import { useAppContext } from '../AppContext';

function useIsQuoter() {    
  const { client } = useAppContext();
  
  const isBasic = useMemo(() => {
    return client.type === 'basic';
  }, [client.type]);

  return { isBasic };
}

export default useIsQuoter;