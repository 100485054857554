import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  IconButton,
  Alert,
} from "@mui/material";

import { useState, useEffect, useRef, useMemo } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import request from "../utils/request";

import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";

import { useAppContext } from "../AppContext";
import useIsQuoter from "../hook/useIsQuoter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxWidth: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ContactModal = (props) => {
  const { client, items, uploadId, formatCurrency, setStatus, setPdf } =
    useAppContext();
  const { isBasic } = useIsQuoter();

  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [msg, setMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [key, setKey] = useState(false);
  const [error, setError] = useState(false);

  const formRef = useRef();

  //const { values, submitForm } = useFormikContext();

  const handleClose = () => {
    if (loading) return;
    setOpen(false);
    props.setOpen(false);

    setSubmitted(false);
  };

  const parts = useMemo(() => {
    let tPrice = 0;
    const content = items.map((item, index) => {
      tPrice += item.price;
      return (
        <Grid key={index} container spacing={0} sx={{ flex: "0 0 auto" }}>
          <Grid item xs={8} sx={{ overflow: "hidden" }}>
            <Typography title={item.filename} variant="body1">
              {item.filename}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" sx={{ textAlign: "right" }}>
              {formatCurrency.format(item.price)}
            </Typography>
          </Grid>
        </Grid>
      );
    });

    let taxValue = parseFloat(client.tax);
    let tax = parseFloat(
      (Math.round(tPrice * (taxValue / 100) * 100) / 100).toFixed(2)
    );
    setPrice(tPrice);
    setTaxPrice(tax);
    setTotalPrice(tPrice + tax);

    return content;
  }, [items]);

  useEffect(() => {
    setOpen(props.open);
    if (!formRef.current) return;
    setEmail(email);
    formRef.current.setFieldValue("email", email);
  }, [props.open]);

  useEffect(() => {
    if (!client) return;
    if (!formRef.current) return;
    formRef.current.setFieldValue("email", email);
  }, [email]);

  const submitContact = async (values) => {
    let data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      company: company,
      street: street,
      city: city,
      zip: zip,
      msg: msg,
      requestId: uploadId,
    };

    setLoading(true);
    try {
      setError(false);
      const json = await request("/request/contact/" + uploadId, data);
      if (json.r == 1) {
        setSubmitted(true);
        setKey(json.key);
        setStatus(1);
        setPdf(json.pdf);
      } else {
        setError(true);
      }
    } catch (e) {
      if (e.status === 500) {
        console.error(e.msg);
        setError(true);
      }
    }
    setLoading(false);
  };

  if (!client) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          firstname: firstname,
          lastname: lastname,
          email: email,
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string()
            .min(2)
            .max(40)
            .required("Firstname is required."),
          lastname: Yup.string()
            .min(2)
            .max(40)
            .required("Lastname is required."),
          email: Yup.string().min(2).max(40).required("E-Mail is required."),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            submitContact(values);
            setStatus({ success: false });
            setSubmitting(false);
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            <form id="contact-form" noValidate onSubmit={handleSubmit}>
              <DialogTitle>
                {!submitted && (
                  <Box>{client.contactBoxLabel || "Instant Quote"}</Box>
                )}
                {submitted && (
                  <Box>
                    {" "}
                    {client.contactFinishedLabel || "Reqeust submitted"}{" "}
                  </Box>
                )}
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {!submitted && (
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body1" gutterBottom>
                        {client.contactBoxText ||
                          'Click on "CONTACT US" to submit the request to our team. We will contact you shortly.<br/>You can also download the quote as a PDF.'}
                      </Typography>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="firstname"
                            label={
                              <>
                                <span>Firstname</span>
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            variant="standard"
                            value={values.firstname}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFirstname(e.target.value);
                              handleChange(e);
                            }}
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                          />
                          {touched.firstname && errors.firstname && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-firstname"
                            >
                              {errors.firstname}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="lastname"
                            label={
                              <>
                                <span>Lastname</span>
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            variant="standard"
                            value={values.lastname}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setLastname(e.target.value);
                              handleChange(e);
                            }}
                            error={Boolean(touched.lastname && errors.lastname)}
                          />
                          {touched.lastname && errors.lastname && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-lastname"
                            >
                              {errors.lastname}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="email"
                            label={
                              <>
                                <span>E-Mail</span>
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            variant="standard"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              handleChange(e);
                            }}
                            error={Boolean(touched.email && errors.email)}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email"
                            >
                              {errors.email}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="contact-phone"
                            label="Phone"
                            variant="standard"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="contact-company"
                            label="Company name"
                            variant="standard"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="contact-street"
                            label="Street"
                            variant="standard"
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="contact-city"
                            label="City"
                            variant="standard"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="contact-zip"
                            label="Postal code"
                            variant="standard"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField
                            fullWidth
                            id="contact-message"
                            label="Message"
                            variant="standard"
                            multiline
                            rows={4}
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {!isBasic && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "stretch",
                            height: "100%",
                            maxHeight: "300px",
                          }}
                        >
                          <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%" }}>{parts}</Box>
                          </Box>
                          <Divider sx={{ mb: 1 }} />
                          <Grid container spacing={0} sx={{ flex: "0 0 auto" }}>
                            <Grid item xs={6}>
                              <Typography variant="body1">Subtotal</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ textAlign: "right" }}
                              >
                                {formatCurrency.format(price)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={0} sx={{ flex: "0 0 auto" }}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                Tax {client.tax}%
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ textAlign: "right" }}
                              >
                                {formatCurrency.format(taxPrice)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid
                            container
                            spacing={0}
                            sx={{ flex: "0 0 auto", mt: 1 }}
                          >
                            <Grid item xs={6}>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                              >
                                Total
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="h6"
                                sx={{ textAlign: "right", fontWeight: "bold" }}
                              >
                                {formatCurrency.format(totalPrice)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {error && (
                        <Alert severity="error">
                          An error occurred. Please try again later.
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                )}

                {submitted && (
                  <>
                    <Typography variant="body1" sx={{ mb: 3 }} gutterBottom>
                      {client.contactFinishedText ||
                        "Thank you for your request. We will contact you shortly. You can also download the quote as a PDF."}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        target="_blank"
                        href={
                          process.env.REACT_APP_S3_PATH +
                          client.quoterKey +
                          "/requests/" +
                          uploadId +
                          "/" +
                          encodeURIComponent(client.companyName) +
                          "-Quotation-" +
                          key +
                          ".pdf"
                        }
                      >
                        {client.downloadButtonLabel || "Download Quotation"}
                      </Button>
                    </Typography>
                  </>
                )}
              </DialogContent>
              {!submitted && (
                <DialogActions sx={{ mb: 1, px: 3 }}>
                  <Grid container spacing={5} justifyContent="flex-end">
                    <Grid item md={8}></Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        type="submit"
                        startIcon={<ConnectWithoutContactIcon />}
                      >
                        {client.contactButtonLabel ||
                          (isBasic ? "Contact Us" : "Get Quote")}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              )}
            </form>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ContactModal;
