import {
  Box,
  Card,
  CardContent,
  CardHeader,
  fabClasses,
  Grid,
  Button,
  Divider,
  CardActions,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  circularProgressClasses,
  Switch,
  Popover,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import React, {
  useRef,
  forwardRef,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
} from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {
  PerspectiveCamera,
  OrbitControls,
  Environment,
  Lightformer,
} from "@react-three/drei";
import * as THREE from "three";
import { Box3, Vector3 } from "three";

import { useAppContext } from "../AppContext";

import { calculateBoundingBox, calculateVolume } from "../utils/helper";
import BoundingBox from "../utils/BoundingBox";

import request from "../utils/request";
import useIsQuoter from "../hook/useIsQuoter";

const getScale = (size, buildPlate) => {
  let x = buildPlate[0] / size[0];
  let y = buildPlate[1] / size[2];
  let z = buildPlate[2] / size[1];

  let scale = Math.min(x, y, z);
  if (scale > 1) scale = 1;
  return scale;
};

const Viewer = (props) => {
  const {
    client,
    quoterId,
    items,
    item,
    color,
    status,
    setItems,
    setItem,
    updateItem,
    buildPlate,
    deleteItem,
  } = useAppContext();
  const { isBasic } = useIsQuoter();

  const [scale, setScale] = useState(1);
  const [bx, setBx] = useState(0);
  const [by, setBy] = useState(0);
  const [bz, setBz] = useState(0);
  const [cameraPosition, setCameraPosition] = useState([50, 100, 0]);
  const [objectPosition, setObjectPosition] = useState([0, 0, 0]);
  const [targetPoint, setTargetPoint] = useState([0, 0, 0]);
  const [wireframe, setWireframe] = useState(false);
  const [boundingBox, setBoundingBox] = useState(true);

  const [infoAnchorEl, setInfoAnchorEl] = useState(null);

  const objColor = useMemo(() => {
    if (!item) return { hex: "#ffffff", transparency: 0 };

    if (isBasic) {
      const c = client.colors.find((c) => c._id === color);
      return c;
    }

    const foundProcess = client.processes.find((p) => p._id === item.process);
    if (!foundProcess) return { hex: "#ffffff", transparency: 0 };

    const foundMaterialCategory = foundProcess.materialCategories.find(
      (m) => m._id === item.materialCategory
    );
    if (!foundMaterialCategory) return { hex: "#ffffff", transparency: 0 };

    const foundColor = foundMaterialCategory.colors.find(
      (c) => c._id === color
    );
    if (!foundColor) return { hex: "#ffffff", transparency: 0 };

    return foundColor;
  }, [item, color]);

  const cameraRef = useRef();
  const controlRef = useRef();
  const meshRef = useRef();

  let floorStyle = "chessboard.jpg";
  switch (client.floorStyle) {
    case "chessboard-colorless":
      floorStyle = "chessboard-colorless.png";
      break;
    case "white":
      floorStyle = "white.jpg";
      break;
    case "black":
      floorStyle = "black.jpg";
      break;
    case "transparent":
      floorStyle = "transparent.png";
      break;
    default:
      floorStyle = "chessboard.jpg";
  }

  const colorMap = useLoader(
    THREE.TextureLoader,
    process.env.REACT_APP_API_PATH + "/../static/" + floorStyle
  );
  colorMap.repeat.set(10, 10);
  colorMap.wrapS = colorMap.wrapT = THREE.RepeatWrapping;

  const calcBB = (geometry) => {
    let b = calculateBoundingBox(geometry);
    let scale = getScale(b, buildPlate);

    setScale(scale);
    setBx(b[0]);
    setBy(b[1]);
    setBz(b[2]);
  };

  const centerCamera = () => {
    controlRef.current.reset();

    let v = new Vector3(0, 0, 0);
    let center = new Vector3(0, 0, 0);
    let size = 0;
    if (item.geometry.boundingBox) {
      center = item.geometry.boundingBox.getCenter(v);
      size = item.geometry.boundingBox.max.z - item.geometry.boundingBox.min.z;
    } else if (item.geometry.boundingSphere) {
      center = item.geometry.boundingSphere.center;
    }

    setCameraPosition([bx * 2.5 + 10, by, 0]);
    setTargetPoint([0, (size / 2) * scale, 0]);

    setObjectPosition([
      -center.x * scale,
      (size / 2) * scale - center.z * scale,
      center.y * scale,
    ]);
    //center.z*scale+size/2*scale
    //onCameraChanged();
  };

  useEffect(() => {
    if (!item) return;
    if (!item.geometry) return;
    if (!item.geometry.boundingBox && !item.geometry.boundingSphere) return;

    let v = new Vector3(0, 0, 0);
    let center = new Vector3(0, 0, 0);
    let size = 0;
    if (item.geometry.boundingBox) {
      center = item.geometry.boundingBox.getCenter(v);
      size = item.geometry.boundingBox.max.z - item.geometry.boundingBox.min.z;
    } else if (item.geometry.boundingSphere) {
      center = item.geometry.boundingSphere.center;
    }

    setObjectPosition([
      -center.x * scale,
      (size / 2) * scale - center.z * scale,
      center.y * scale,
    ]);
    if (!item.cameraPosition) {
      setCameraPosition([bx * 2.5 + 10, by, 0]);
      setTargetPoint([0, (size / 2) * scale, 0]);
    } else {
      setCameraPosition(item.cameraPosition);
      setTargetPoint(item.cameraTarget);
    }
  }, [bx, by, bz, scale, item]);

  const onCameraChanged = () => {
    const id = item._id;
    setItem((oldItem) => {
      const newItem = { ...oldItem };
      newItem.cameraPosition = cameraRef.current.position.clone();
      newItem.cameraTarget = controlRef.current.target.clone();
      return newItem;
    });
    setItems((oldItems) => {
      const newItems = oldItems.map((i) => {
        if (i._id === id) {
          i.cameraPosition = cameraRef.current.position.clone();
          i.cameraTarget = controlRef.current.target.clone();
        }

        return i;
      });
      return newItems;
    });
    //props.setCameraPosition(cameraRef.current.position, controlRef.current.target);
  };

  const duplicateItem = async () => {
    if (!item) return;
    const newItem = { ...item, index: items.length };
    const geometry = newItem.geometry;
    delete newItem.geometry;
    const json = await request(
      "/request/copy/" + newItem.requestId,
      { ...newItem, quoterId: quoterId },
      {}
    );
    if (json.r == 1) {
      newItem.geometry = geometry;
      newItem._id = json.id;
      newItem.cameraPosition = newItem.cameraPosition.clone();
      newItem.cameraTarget = newItem.cameraTarget.clone();
      setItem(newItem);
      setItems((g) => {
        return [...g, newItem];
      });
    }
  };

  const setInch = (useInch) => {
    setItem((oldItem) => {
      const newItem = { ...oldItem };
      newItem.useInch = useInch;
      console.log(newItem);
      return newItem;
    });
  };

  const handleInfoOpen = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  let btnDisabled = false;
  if (!item || !item.geometry) btnDisabled = true;

  useEffect(() => {
    if (!item || !item.geometry) return;

    const geometry = item.geometry;
    calcBB(geometry);
  }, [item]);

  if (!item) return null;

  const infoOpen = Boolean(infoAnchorEl);

  const loading = (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        zIndex: 999,
      }}
    >
      {item.status == 0 && (
        <>
          <FacebookCircularProgress />
          <Typography variant="h4" sx={{ mt: 2 }} color="black">
            Processing file...
          </Typography>
        </>
      )}
      {item.status == 10 && (
        <>
          <Typography variant="h4" color="red">
            Error
          </Typography>
          <Typography variant="body1" color="black">
            {item.message}
          </Typography>
        </>
      )}
    </Box>
  );

  return (
    <Box
      id="calculator-viewer"
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ flexGrow: 1, position: "relative", mb: 2, maxHeight: "90%" }}>
        {(!item.geometry || item.status == 10) && loading}
        {item.geometry && (
          <Canvas flat={true}>
            <PerspectiveCamera
              position={cameraPosition}
              rotation={[0, 1, 0]}
              ref={cameraRef}
              makeDefault
            />
            <OrbitControls
              ref={controlRef}
              target={targetPoint}
              onEnd={onCameraChanged}
              camera={cameraRef.current}
              enableDamping={false}
            />

            <directionalLight position={[0, 250, 0]} intensity={0.5} />
            <pointLight position={[250, 250, 0]} intensity={1} />
            <pointLight position={[0, 250, 250]} intensity={1} />
            <pointLight position={[0, 250, -250]} intensity={1} />
            <pointLight position={[-250, 250, 0]} intensity={1} />
            <pointLight position={[0, -250, 0]} intensity={1} />
            <ambientLight intensity={0.7} />

            {/* <directionalLight
                            position={[-125, 0, -125]}
                            intensity={0.5}
                        /> */}
            <Model
              ref={meshRef}
              position={objectPosition}
              scale={scale}
              wireframe={wireframe}
              color={objColor}
              geometry={item.geometry}
              rotation={[Math.PI / -2, 0, 0]}
            />
            {boundingBox && <BoundingBox color="#000000" size={buildPlate} />}
            <mesh rotation={[-Math.PI / 2, 0, 0]}>
              <planeBufferGeometry
                attach="geometry"
                args={[buildPlate[0], buildPlate[2]]}
              />
              <meshPhongMaterial
                transparent={true}
                map={colorMap}
                attach="material"
              />
            </mesh>
          </Canvas>
        )}
        {scale != 1 && (
          <Box sx={{ position: "absolute", bottom: 10, left: 10, zIndex: 999 }}>
            <Typography variant="subtitle1" color="black">
              Scale factor {Math.round(scale * 100) / 100}
            </Typography>
          </Box>
        )}
        {status > 0 && (
          <Box sx={{ position: "absolute", bottom: 10, left: 10, zIndex: 999 }}>
            <Typography variant="subtitle1" color="black">
              Request status:
              {status == 1 && " submitted"}
              {status == 2 && " in progress"}
              {status == 3 || (status == 4 && " closed")}
              {isNaN(status) && " submitted"}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ px: 1, display: "flex", justifyContent: "space-between" }}>
            {client.enableInch && (
              <Tooltip title="Convert mm to inch" placement="top" arrow>
                <Switch
                  checked={client.inch}
                  onChange={(e) => setInch(e.target.checked)}
                />
              </Tooltip>
            )}
            {client.enableBoundingBox && (
              <>
                <IconButton
                  disabled={btnDisabled}
                  color="primary"
                  aria-owns={infoOpen ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handleInfoOpen}
                  onMouseLeave={handleInfoClose}
                  component="span"
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={infoOpen}
                  anchorEl={infoAnchorEl}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  disableRestoreFocus
                >
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body1">Model info</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography component="div" variant="caption">
                      Bounding box:
                      {item.useInch &&
                        bx * 0.0393701 +
                          " x " +
                          by * 0.0393701 +
                          " x " +
                          bz * 0.0393701 +
                          " in"}
                      {!item.useInch && bx + " x " + by + " x " + bz + " mm"}
                    </Typography>

                    <Typography component="div" variant="caption">
                      Volume:
                      {item.useInch && item.volume * 0.0610237 + " in³"}
                      {!item.useInch && item.volume + " mm³"}
                    </Typography>
                  </Box>
                </Popover>
              </>
            )}
            {client.controlDuplicate && (
              <Tooltip title="Duplicate model" placement="top" arrow>
                <IconButton
                  disabled={btnDisabled || status > 0}
                  color="primary"
                  onClick={duplicateItem}
                  component="span"
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {client.controlWireframe && (
              <Tooltip
                title={wireframe ? "Disable wireframe" : "Enable wireframe"}
                placement="top"
                arrow
              >
                <IconButton
                  disabled={btnDisabled}
                  color="primary"
                  onClick={() => setWireframe(!wireframe)}
                  component="span"
                >
                  <FilterFramesIcon />
                </IconButton>
              </Tooltip>
            )}
            {client.controlPrintSpace && (
              <Tooltip
                title={
                  boundingBox ? "Disable print space" : "Enable print space"
                }
                placement="top"
                arrow
              >
                <IconButton
                  disabled={btnDisabled}
                  color="primary"
                  onClick={() => setBoundingBox(!boundingBox)}
                  component="span"
                >
                  <ViewInArIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Center camera" placement="top" arrow>
              <IconButton
                disabled={btnDisabled}
                color="primary"
                onClick={() => centerCamera()}
                component="span"
              >
                <ControlCameraIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete model" placement="top" arrow>
              <IconButton
                disabled={btnDisabled || status > 0}
                color="primary"
                onClick={() => deleteItem(item)}
                component="span"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

const Model = forwardRef((props, ref) => {
  const opacity = useMemo(() => {
    if (props.color.transparency === undefined) return 1;
    return (100 - props.color.transparency) / 100;
  }, [props.color.transparency]);

  return (
    <mesh position={props.position} {...props} ref={ref}>
      <meshStandardMaterial
        wireframe={props.wireframe}
        color={props.color.hex}
        opacity={opacity}
        transparent
        metalness={0.65}
        specular={0x111111}
        // shininess: 200,
        // roughness: 0.9,
        roughness={0.5}
      />
    </mesh>
  );
});

const FacebookCircularProgress = (props) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={80}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={80}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

export default Viewer;
