import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

import { useAppContext } from "../AppContext";
import { useTheme } from "@emotion/react";
import useIsQuoter from "../hook/useIsQuoter";

function MenuItem({ menuItem }) {
  const { client, quoterId, items, item, setItem, deleteItem, formatCurrency } =
    useAppContext();

  const { isBasic } = useIsQuoter();

  const statusPrice = useMemo(() => {
    let c;
    if (menuItem.loading < 1) {
      if (menuItem.status == 10) {
        c = (
          <IconButton
            sx={{ p: 0 }}
            aria-label="delete"
            size="small"
            onClick={() => deleteItem(menuItem)}
          >
            <DeleteIcon />
          </IconButton>
        );
      } else if (isBasic){
        return null;
      }

      if (menuItem.status == 1) {
        c = (
          <Typography noWrap>
            {formatCurrency.format(menuItem.price)}
          </Typography>
        );
      }
    } else {
      c = <CircularProgress size="15px" />;
    }

    return (
      <Grid menuItem xs sx={{ textAlign: "right" }}>
        {c}
      </Grid>
    );
  }, [menuItem, items]);

  return (
    <ListItem key={menuItem._id} disablePadding>
      <ListItemButton
        selected={item != null && item._id === menuItem._id}
        onClick={(e) => {
          setItem(menuItem);
        }}
      >
        <ListItemIcon sx={{ minWidth: 34 }}>
          <InsertDriveFileIcon />
        </ListItemIcon>
        <ListItemText
          sx={{ overflow: "hidden", width: "100%" }}
          primary={
            <>
              <Grid container spacing={0}>
                <Grid menuItem xs>
                  <Typography
                    sx={{ overflow: "hidden" }}
                    color={menuItem.status == 10 ? "red" : ""}
                    title={menuItem.filename}
                    noWrap
                  >
                    {menuItem.filename}
                  </Typography>
                </Grid>
                {statusPrice}
              </Grid>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default MenuItem;
