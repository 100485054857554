import {useState, useEffect} from 'react';
import {
    Box,
    Button,
    List, 
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Grid
} from '@mui/material';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

import MenuItem from './MenuItem';

import { useAppContext } from '../AppContext';
import { useTheme } from '@emotion/react';

const MenuList = (props) => {
    const theme = useTheme
    const {
        client,
        quoterId,
        items,
        item,
        setItem,
        deleteItem,
        formatCurrency,
    } = useAppContext();

    return (
        <List sx={{ p: 0 }}>
                {items.map((i, index) => {
                    if(!i) return null;
                    return (
                        <MenuItem key={i._id} menuItem={i} />
                    );
                })}
        </List>
    );
}

export default MenuList;
